import { translateManager } from '../services/TranslateManager'
import { FRAME_COLORS, MATERIAL_TYPES } from '@mixtiles/web-backend-shared'
import { isFramedMaterial } from './materialTypeUtils'
import { WALL_FRAME_PRESET_CONFIG } from '../components/WallDesigner/WallDesignerProvider/GalleryWallsV2Presets'

export const getMaterialName = (
  materialType: MATERIAL_TYPES,
  getTranslation = translateManager.get
) => getTranslation(`order.materials.${materialType.toLowerCase()}`)

export const getFrameColorName = (
  frameColor: FRAME_COLORS,
  shouldAddWide?: boolean,
  materialType?: MATERIAL_TYPES,
  getTranslation = translateManager.get
) => {
  if (materialType && !isFramedMaterial(materialType)) {
    return getMaterialName(materialType, getTranslation)
  }
  const result = getTranslation(`order.frame_colors.${frameColor}`)
  return shouldAddWide ? `${getTranslation('designer.wide')} ${result}` : result
}

export const getFramePresetName = (
  presetId: string,
  getTranslation = translateManager.get
) => {
  const preset = WALL_FRAME_PRESET_CONFIG.find(
    (preset) => preset.id === presetId
  )
  if (preset?.configs.length === 1) {
    return getFrameColorName(
      preset.configs[0].frameColor || FRAME_COLORS.NONE,
      preset.configs[0].materialType === MATERIAL_TYPES.WIDE_FRAME,
      preset.configs[0].materialType,
      getTranslation
    )
  }
  return getTranslation(`order.frame_presets.${presetId}`)
}
