import { API_METHODS, createApiFunction } from './apiProvider'
import { type PRODUCT_TYPES } from '../services/ProductTypeState'

export const getFramesApi = (locale: string) =>
  createApiFunction(
    API_METHODS.GET,
    `skus/productType?locale=${locale}`,
    null,
    false
  )

export type GetFramesApiResponse = Record<PRODUCT_TYPES, string[]>
