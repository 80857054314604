import {
  CANVAS_SIZES,
  formatPrice,
  MATERIAL_TYPES,
  TILE_SIZES,
  type FRAME_COLORS,
} from '@mixtiles/web-backend-shared'
import { useRouteLoaderData } from '@remix-run/react'
import { usePricing } from 'components/Pricing/PricingProvider'
import { insertAfterSize, ALL_SIZES } from 'pages/DiscoveryPage/DiscoveryConsts'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useExperimentManager } from 'services/ExperimentManager/ExperimentManager'
import { useKeys } from 'services/KeysProvider'
import { generateAvailableMaterialsList } from 'services/MaterialsAsColorManager/MaterialsAsColorManager'
import { getSizeName, isSizesInInches } from 'services/SizesManager'
import { getFrameColorName, getMaterialName } from 'utils/colorUtils'

export function useFrameColorName() {
  const { t } = useTranslation()

  return {
    getFrameColorName: useCallback(
      (
        frameColor: FRAME_COLORS,
        shouldAddWide?: boolean,
        materialType?: MATERIAL_TYPES
      ) => getFrameColorName(frameColor, shouldAddWide, materialType, t),
      [t]
    ),
    getMaterialName: useCallback(
      (materialType: MATERIAL_TYPES) => getMaterialName(materialType, t),
      [t]
    ),
  }
}
export function useSizeName() {
  const { t } = useTranslation()
  const { ipCountry } = useKeys()
  const isInches = useMemo(() => isSizesInInches(ipCountry), [ipCountry])

  return {
    isSizesInInches: isInches,
    getSizeName: useCallback(
      (size: string) => getSizeName(size, { country: ipCountry, t }),
      [t]
    ),
  }
}
export function useOrderedSizes() {
  const experimentManager = useExperimentManager()

  return useCallback(
    (
      materialType = MATERIAL_TYPES.CLASSIC // this is a small hack to make sure this function works in SSR
    ) => {
      if (materialType === MATERIAL_TYPES.STICKER) {
        return [TILE_SIZES.SQUARE_30X30, TILE_SIZES.SQUARE_40X40]
      }
      if (materialType === MATERIAL_TYPES.CANVAS) {
        return experimentManager.isEnabled('canvas-16x20')
          ? insertAfterSize(CANVAS_SIZES, TILE_SIZES.RECTANGLE_16X12, [
              TILE_SIZES.RECTANGLE_16X20,
              TILE_SIZES.RECTANGLE_20X16,
            ])
          : CANVAS_SIZES
      }

      const sizes: TILE_SIZES[] = []
      if (
        experimentManager.isEnabled('size-4x4') &&
        materialType === MATERIAL_TYPES.FRAMELESS
      ) {
        sizes.push(TILE_SIZES.SQUARE_4X4)
      }

      sizes.push(...ALL_SIZES)

      if (experimentManager.isEnabled('xl-sizes')) {
        sizes.push(TILE_SIZES.RECTANGLE_27X36, TILE_SIZES.RECTANGLE_36X27)
      }

      if (experimentManager.isEnabled('size-22x44')) {
        sizes.push(TILE_SIZES.RECTANGLE_22X44, TILE_SIZES.RECTANGLE_44X22)
      }

      return sizes
    },
    [experimentManager]
  )
}
export function useFormatPrice() {
  const { ipCountry } = useKeys()
  const { pricingData } = usePricing()

  return useCallback(
    (price: number) =>
      formatPrice({
        price,
        country: ipCountry,
        shouldRound: false,
        roundingPrecision: 2,
        currency: pricingData.currency,
      }),
    [pricingData, ipCountry]
  )
}
export function useMaterialsAsColor() {
  const experimentManager = useExperimentManager()
  const { isMaterialsUnderFrameEnabled } = useRouteLoaderData('root')

  const isCanvasEnabled = experimentManager.isEnabled('canvas')
  const availableMaterialsList = useMemo(
    () => generateAvailableMaterialsList(isCanvasEnabled),
    [isCanvasEnabled]
  )

  return {
    isEnabled: isMaterialsUnderFrameEnabled,
    availableMaterialsList,
  }
}
