import {
  FRAME_COLORS,
  getFrameColor,
  getFrameFromSKU,
  getHeightAndWidthToTileSizeName,
  isCenterpiece,
  MATERIAL_TYPES,
  SIZE_SEPARATOR,
  TILE_MATERIALS,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import { useMaterialsAsColor, useOrderedSizes } from 'hooks/tiles'
import groupBy from 'lodash/groupBy'
import intersection from 'lodash/intersection'
import mapValues from 'lodash/mapValues'
import React, {
  type ComponentType,
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import useApi from '../../api/apiProvider'
import {
  getFramesApi,
  type GetFramesApiResponse,
} from '../../api/availableFramesProvider.api'
import { COUNTRIES } from '../../config/countries-data'
import useOnMount from '../../hooks/useOnMount'
import { MIXED_MATERIALS } from '../../pages/PhotoStyler/TileDesignerConsts'
import { addressManager } from '../../services/AddressManager'
import { countryManager } from '../../services/CountryManager'
import { useExperimentManager } from '../../services/ExperimentManager/ExperimentManager'
import { logger } from '../../services/logger'
import { PRODUCT_TYPES } from '../../services/ProductTypeState'
import { getTileStyleData } from '../../services/tilesStyling'
import { isFramedMaterial } from '../../utils/materialTypeUtils'
import { type Tile } from '../TilesProvider/TilesProvider.types'
import {
  type AvailableStockContextValue,
  type ProductToMaterialToFrameToSize,
  type ProductToMaterialToSizeToFrame,
  type StockMaterialType,
  type WithStockProps,
} from './AvailableStockProvider.types'

const AvailableFramesContext = createContext<AvailableStockContextValue | null>(
  null
)
AvailableFramesContext.displayName = 'AvailableFramesContext'

// This function gets a mapping of productType to materialType to frame to sizes and transform it to product type to materialType to size to frames
function transformDataToPTToMTToSizeToFrames(
  PTToMTToFrameToSizes: ProductToMaterialToFrameToSize
): ProductToMaterialToSizeToFrame {
  return mapValues(PTToMTToFrameToSizes, (mtToFrameToSizes) =>
    mapValues(mtToFrameToSizes, (frameToSizes) => {
      const sizeToFrames = {} as Record<TILE_SIZES, FRAME_COLORS[]>
      Object.entries(frameToSizes).forEach(([frame, sizes]) => {
        sizes.forEach((size) => {
          sizeToFrames[size] = (sizeToFrames[size] ?? []).concat(
            frame as FRAME_COLORS
          )
        })
      })
      return sizeToFrames
    })
  )
}

function transformDataToPTToMTToFrameToSizes(
  data: GetFramesApiResponse
): ProductToMaterialToFrameToSize {
  const heightAndWidthToTileSizeName: Record<string, TILE_SIZES> =
    getHeightAndWidthToTileSizeName()
  return mapValues(data, (skus) =>
    mapValues(
      groupBy(
        skus.map((sku) => getFrameFromSKU(sku)),
        (frame) => frame.materialType
      ),
      (frames) =>
        mapValues(
          groupBy(frames, (frame) => frame.color),
          (frames) =>
            addFlippedSizes(frames.map((frame) => frame.size))
              .map((size) => heightAndWidthToTileSizeName[size])
              .filter((size) => size !== undefined)
        )
    )
  ) as ProductToMaterialToFrameToSize
}

const FRAMES_ORDER = [
  FRAME_COLORS.BLACK,
  FRAME_COLORS.WHITE,
  FRAME_COLORS.WOOD,
  FRAME_COLORS.RED_ASH_WOOD,
  FRAME_COLORS.NONE,
]

function addFlippedSizes(sizes: string[]): string[] {
  const allSizes = new Set(sizes)
  sizes.forEach((size) => {
    const sizeComponents = size.split(SIZE_SEPARATOR)
    if (sizeComponents.length === 2) {
      const flippedSize = sizeComponents[1] + SIZE_SEPARATOR + sizeComponents[0]
      allSizes.add(flippedSize)
    }
  })
  return Array.from(allSizes)
}

export function withAvailableStockProvider<T extends WithStockProps>(
  Component: ComponentType<T>
) {
  return React.forwardRef<unknown, Omit<T, keyof WithStockProps>>(
    (props, ref) => (
      <AvailableFramesContext.Consumer>
        {(contexts) => (
          <Component {...(props as T)} ref={ref} stock={contexts} />
        )}
      </AvailableFramesContext.Consumer>
    )
  )
}

function AvailableStockProvider({ children }: PropsWithChildren) {
  const api = useApi()
  const experimentManager = useExperimentManager()
  const { isEnabled: isMaterialsAsColorEnabled } = useMaterialsAsColor()
  const getOrderedSizes = useOrderedSizes()

  // PT stands for productType
  const [PTToMTToFrameToSizes, setPTToMTToFrameToSizes] =
    useState<ProductToMaterialToFrameToSize | null>(null)
  const [PTToMTToSizeToFrames, setPTToMTToSizeToFrames] =
    useState<ProductToMaterialToSizeToFrame | null>(null)
  const [localSKUs, setLocalSKUs] =
    useState<ProductToMaterialToSizeToFrame | null>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useOnMount(() => {
    const currentAddress = addressManager.getCurrentAddress()
    if (currentAddress !== null) {
      initStockByAddress({
        address: currentAddress,
      })
    }
  })

  async function initStockByAddress({
    address,
  }: {
    address: { countryId: string }
  }) {
    try {
      // @ts-ignore
      const locale = COUNTRIES[address.countryId].code
      const response: GetFramesApiResponse = await api.call(
        getFramesApi(locale)
      )
      const data = transformDataToPTToMTToFrameToSizes(response)
      setLocalSKUs(transformDataToPTToMTToSizeToFrames(data))
    } catch (error) {
      logger.error('Failed to get skus by zone', error)
    }
  }

  async function initFrames() {
    try {
      const locale = countryManager.getCountry()
      const response = await api.call(getFramesApi(locale))
      const data = transformDataToPTToMTToFrameToSizes(response)

      setPTToMTToFrameToSizes(data)
      setPTToMTToSizeToFrames(transformDataToPTToMTToSizeToFrames(data))
      setIsLoaded(true)
    } catch (error) {
      logger.error('Failed to get the available frames from the server', error)
    }
  }

  useEffect(() => {
    initFrames()
  }, [])

  function getSupportedFramesForSize({
    productType = PRODUCT_TYPES.CLASSIC,
    size,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    productType?: PRODUCT_TYPES
    size: TILE_SIZES
    materialType?: MATERIAL_TYPES
  }): FRAME_COLORS[] {
    const backendMaterialType =
      materialType === MATERIAL_TYPES.FRAMELESS
        ? MATERIAL_TYPES.CLASSIC
        : materialType
    let supportedFrames =
      PTToMTToSizeToFrames?.[productType]?.[backendMaterialType]?.[size] ?? []
    if (
      isFramedMaterial(materialType) &&
      productType === PRODUCT_TYPES.CLASSIC
    ) {
      supportedFrames = supportedFrames.filter(
        (frame) => frame !== FRAME_COLORS.NONE
      )
    } else if (materialType === MATERIAL_TYPES.FRAMELESS) {
      if (isMaterialsAsColorEnabled) {
        supportedFrames = [FRAME_COLORS.NONE]
      } else {
        supportedFrames = supportedFrames.filter(
          (frame) => frame === FRAME_COLORS.NONE
        )
      }
    }

    return FRAMES_ORDER.filter((frame) => {
      if (
        productType === PRODUCT_TYPES.CLASSIC &&
        size === TILE_SIZES.SQUARE_8X8 &&
        frame === FRAME_COLORS.WOOD &&
        !experimentManager.isEnabled('wood-8x8')
      ) {
        return false
      }
      return supportedFrames.includes(frame)
    })
  }

  function isFrameSupportedForSize({
    frameColor,
    size,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    frameColor: FRAME_COLORS
    size: TILE_SIZES
    materialType?: StockMaterialType
  }): boolean {
    if (materialType === MIXED_MATERIALS) {
      // if the material is mixed materials than we want to check if the frame is supported at least in one material
      return TILE_MATERIALS.some((tileMaterial) =>
        isFrameSupportedForSizeInSingleMaterial({
          frameColor,
          size,
          materialType: tileMaterial,
        })
      )
    }
    return isFrameSupportedForSizeInSingleMaterial({
      frameColor,
      size,
      materialType,
    })
  }

  function isFrameSupportedForSizeInSingleMaterial({
    frameColor,
    size,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    frameColor: FRAME_COLORS
    size: TILE_SIZES
    materialType?: MATERIAL_TYPES
  }): boolean {
    const supportedFrames = getSupportedFramesForSize({ size, materialType })
    return supportedFrames.includes(frameColor)
  }

  function isFrameSupportedForMaterial({
    frameColor,
    material,
  }: {
    frameColor: FRAME_COLORS
    material: MATERIAL_TYPES
  }): boolean {
    const sizes = getSizesInStockForMaterial({ materialType: material })
    return sizes.some((size) =>
      isFrameSupportedForSize({ frameColor, size, materialType: material })
    )
  }

  function getSupportedFramesForSizes({
    productType = PRODUCT_TYPES.CLASSIC,
    frameSizes,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    productType?: PRODUCT_TYPES
    frameSizes: TILE_SIZES[]
    materialType?: MATERIAL_TYPES
  }): FRAME_COLORS[] {
    const supportedFramesPerSize = Object.values(frameSizes).map((size) =>
      getSupportedFramesForSize({ productType, size, materialType })
    )
    return intersection(...supportedFramesPerSize)
  }

  function getSupportedSizesForFrame({
    productType = PRODUCT_TYPES.CLASSIC,
    frame,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    productType?: PRODUCT_TYPES
    frame: FRAME_COLORS
    materialType?: MATERIAL_TYPES
  }): TILE_SIZES[] {
    const frameColor = getFrameColor(frame) as FRAME_COLORS
    const backendMaterialType =
      materialType === MATERIAL_TYPES.FRAMELESS
        ? MATERIAL_TYPES.CLASSIC
        : materialType
    return (
      PTToMTToFrameToSizes?.[productType]?.[backendMaterialType]?.[
        frameColor
      ] ?? []
    )
  }

  function getUnsupportedTilesForSize({
    productType = PRODUCT_TYPES.CLASSIC,
    size,
    tiles,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    productType?: PRODUCT_TYPES
    size: TILE_SIZES
    tiles: Tile[]
    materialType?: StockMaterialType
  }): Tile[] {
    if (materialType === MIXED_MATERIALS) {
      return tiles.filter(
        (tile) =>
          !isFrameSupportedForSizeInSingleMaterial({
            frameColor: tile.customStyle.frameColor,
            size,
            materialType: tile.materialType,
          })
      )
    }
    return getUnsupportedTilesForSizeInSingleMaterial({
      productType,
      size,
      tiles,
      materialType,
    })
  }

  function getUnsupportedTilesForSizeInSingleMaterial({
    productType = PRODUCT_TYPES.CLASSIC,
    size,
    tiles,
    materialType = MATERIAL_TYPES.CLASSIC,
  }: {
    productType?: PRODUCT_TYPES
    size: TILE_SIZES
    tiles: Tile[]
    materialType?: MATERIAL_TYPES
  }): Tile[] {
    const tileSizeSupportedFramesColors = getSupportedFramesForSize({
      productType,
      size,
      materialType,
    })

    if (!isFramedMaterial(materialType)) {
      return tileSizeSupportedFramesColors.length > 0 ? [] : tiles
    }

    return tiles.filter((tile) => {
      if (isCenterpiece(tile)) {
        return false
      }
      return !tileSizeSupportedFramesColors.includes(
        getTileStyleData(tile).frameColor
      )
    })
  }

  function getSizesInStockForMaterial({
    materialType,
  }: {
    materialType: StockMaterialType
  }): TILE_SIZES[] {
    if (materialType === MIXED_MATERIALS) {
      const sizesInStock = TILE_MATERIALS.map((material) =>
        getSizesInStockForSingleMaterial({ material })
      )
      return [
        ...new Set(
          sizesInStock.reduce(
            (acc, currentArray) => acc.concat(currentArray),
            []
          )
        ),
      ]
    }
    return getSizesInStockForSingleMaterial({ material: materialType })
  }

  function getSizesInStockForSingleMaterial({
    material,
  }: {
    material: MATERIAL_TYPES
  }): TILE_SIZES[] {
    const supportedSizes = getOrderedSizes(material)
    return supportedSizes.filter(
      (size) =>
        getSupportedFramesForSize({ size, materialType: material }).length !== 0
    )
  }

  function isSupportedSizeForMaterial({
    size,
    material,
  }: {
    size: TILE_SIZES
    material: MATERIAL_TYPES
  }): boolean {
    return getSizesInStockForMaterial({
      materialType: material,
    }).includes(size)
  }

  function* getColorMaterialCombinations(
    materials: MATERIAL_TYPES[],
    forceMaterialsAsColorEnabled = false,
    skipStockCheck = false
  ) {
    for (const material of materials) {
      for (const frameColor of Object.values(FRAME_COLORS)) {
        if (
          shouldDisplayFrame({
            frameColor,
            materialType: material,
            forceMaterialsAsColorEnabled,
            skipStockCheck,
          })
        ) {
          yield { material, frameColor }
        }
      }
    }
  }

  const shouldDisplayFrame = ({
    frameColor,
    materialType,
    forceMaterialsAsColorEnabled = false,
    skipStockCheck = false,
  }: {
    frameColor: FRAME_COLORS
    materialType: MATERIAL_TYPES
    forceMaterialsAsColorEnabled?: boolean
    skipStockCheck?: boolean
  }) => {
    if (
      frameColor === FRAME_COLORS.NONE &&
      !isMaterialsAsColorEnabled &&
      !forceMaterialsAsColorEnabled
    ) {
      return false
    }
    if (
      frameColor === FRAME_COLORS.WOOD &&
      materialType === MATERIAL_TYPES.WIDE_FRAME
    ) {
      return false
    }
    if (
      frameColor === FRAME_COLORS.RED_ASH_WOOD &&
      materialType === MATERIAL_TYPES.CLASSIC
    ) {
      return false
    }

    if (
      [MATERIAL_TYPES.CANVAS, MATERIAL_TYPES.FRAMELESS].includes(
        materialType
      ) !==
      (frameColor == FRAME_COLORS.NONE)
    ) {
      return false
    }
    if (skipStockCheck) {
      return true
    } else {
      return isFrameSupportedForMaterial({
        frameColor,
        material: materialType,
      })
    }
  }

  const contextValue: AvailableStockContextValue = {
    isLoaded,
    getSupportedFramesForSize,
    getSupportedFramesForSizes,
    getSupportedSizesForFrame,
    getUnsupportedTilesForSize,
    getSizesInStockForMaterial,
    isFrameSupportedForSize,
    isFrameSupportedForMaterial,
    isSupportedSizeForMaterial,
    initStockByAddress,
    localSKUs,
    getColorMaterialCombinations,
  }

  return (
    <AvailableFramesContext.Provider value={contextValue}>
      {children}
    </AvailableFramesContext.Provider>
  )
}

function useAvailableFrames() {
  const context = useContext(AvailableFramesContext)
  if (!context) {
    throw new Error(
      'useAvailableFrames must be used within a AvailableFramesContext'
    )
  }
  return context
}

export { AvailableStockProvider, useAvailableFrames }
